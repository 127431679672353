import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthMiddleware = ({ component: Component }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate('/login');
    }
  }, [navigate]);

  return <Component />;
}

export default AuthMiddleware;
