import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginMiddleware = ({ component: Component }) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      navigate('/dashboard');
    }
  }, [navigate]);

  return <Component />;
}

export default LoginMiddleware;
