import React from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utilities/axiosConfig';

const DashboardPage = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axiosInstance.post('/auth/logout');
      localStorage.clear();
      navigate('/login'); // Gunakan navigate untuk pengalihan
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  

  return (
    <div>
      <h1>Dashboard Page</h1>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default DashboardPage;
