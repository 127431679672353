import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utilities/axiosConfig';

const LoginPage = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        emailOrUsername: '',
        password: ''
    });
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/auth/login', formData);
            localStorage.setItem('accessToken', response.data.accessToken);
            localStorage.setItem('refreshToken', response.data.refreshToken);
            localStorage.setItem('userData', JSON.stringify({
                userId: response.data.id,
                userFullname: response.data.name,
                userName: response.data.username,
                userEmail: response.data.email
            }));
            navigate('/dashboard');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
                setFormData({ ...formData, password: '' });
            } else {
                console.error('Login failed:', error);
                setErrorMessage('Failed to login. Please try again later.');
            }
        }
    };

    return (
        <div>
            <h1>Login Page</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="emailOrUsername"
                    placeholder="Email or Username"
                    value={formData.emailOrUsername}
                    onChange={handleChange}
                />
                <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                />
                <button type="submit">Login</button>
            </form>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    );
};

export default LoginPage;
