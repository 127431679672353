import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import ProfilePage from './pages/ProfilePage';
import AuthMiddleware from './middleware/AuthMiddleware';
import LoginMiddleware from './middleware/LoginMiddleware';


function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginMiddleware component={LoginPage} />} />
          <Route path="/dashboard" element={<AuthMiddleware component={DashboardPage} />} />
          <Route path="/profile" element={<AuthMiddleware component={ProfilePage} />} />
        </Routes>
    </Router>
  );
}

export default App;
